import React, { useEffect, useState } from "react";
import { Bars } from "react-loader-spinner";
import "./PartnerLogin.scss";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import RegisterInputForm from "./RegisterInputForm";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import {auth} from "../../helpers/firebase";

function PartnerLogin() {
  const [showLoadingAnim, setShowLoadingAnim] = useState(false);
  const [userType, setRegisterUser] = useState("notRegisterVoyaah");
  const [otpScreen, setOtpScreen] = useState(false);
  const [registrationData, setRegistrationData] = useState({});
  const [appVerified, setAppVerified] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    window.appVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container-id",
        {
            size: "invisible",
            callback: (response: any) => {
              console.log('recaptcha response', response);
            },
            "expired-callback": () => {
                //reCaptcha Response ID expires in about 1 min.
                console.log(`reCaptcha expired`);
                setAppVerified(false);
            },
        }
        
    );
    if (!appVerified) {
        window.appVerifier.verify();
    }
    return () => {
        window.appVerifier.clear();
    };
}, [appVerified]);

useEffect(() => {
  let timer = setInterval(() => { }, 1000);
  if (counter > 0) {
      timer = setInterval(() => setCounter(counter - 1), 1000);
  }
  // counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
  return () => clearInterval(timer);
}, [counter]);

const goBack = () => {
  setCounter(0);
};

const setCounterFunction = (countNumber: any) => {
  setCounter(countNumber);
}


  const setRecaptcha = () => {
    setAppVerified(false);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Login | Voyaah";
  }, []);

  const onChangeValue = (event: any) => {
    setRegisterUser(event.target.value);
  }

  const setOtpFunction = (dataVal: any) => {
    setRegistrationData(dataVal);
    setOtpScreen(true);
  }

  const changePhoneNumber = () => {
    setOtpScreen(false);
  }

  return (
    <>
      <div className="login-section">
        <div id="recaptcha-container-id" />
        <div className="section">
          <div className="login-container">
            <div className="head">Partner Login</div>
            {otpScreen === false &&
            <div style={{ display: 'flex', justifyContent: 'space-around', marginBottom: '20px' }}>
              <label>
                <input
                  type="radio"
                  value="notRegisterVoyaah"
                  checked={userType === "notRegisterVoyaah"}
                  onChange={e=> {onChangeValue(e); setRegistrationData({})}}
                />
                Not Voyaah User
              </label>
              <label>
                <input
                  type="radio"
                  value="registerVoyaah"
                  checked={userType === "registerVoyaah"}
                  onChange={e=> {onChangeValue(e); setRegistrationData({})}}
                />
                Voyaah User
              </label>
            </div>
            }
          {userType === "notRegisterVoyaah" ? otpScreen === false ?
            <RegisterForm setRecaptcha={setRecaptcha} setShowLoadingAnim={setShowLoadingAnim} setOtpFunction={setOtpFunction} registrationData={registrationData} setCounterFunction={setCounterFunction} /> 
            :
            <RegisterInputForm setRecaptcha={setRecaptcha} changePhoneNumber={changePhoneNumber} registrationData={registrationData} counter={counter} setCounterFunction={setCounterFunction} otpScreen={otpScreen} />
            :
            <>
              <div className="login-text">
                Login In With <span>Voyaah</span>
              </div>
              <LoginForm setShowLoadingAnim={setShowLoadingAnim} />
            </>
          }
          </div>
        </div>
      </div>
      {showLoadingAnim && (
        <div className="loader-container">
          <div className="loader">
            <Bars color="#00BFFF" height={50} width={100} />
          </div>
        </div>
      )}
    </>
  );
}

export default PartnerLogin;
