import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import {
	auth,
	registerWithPhoneAndOTP,
	verifyPhoneNumber,
	logInWithEmailAndPassword,
	guestSignIn,
} from "../../helpers/firebase";
import { RecaptchaVerifier } from "firebase/auth";
import regexData from "../../constants/regexData";
import "./Review.scss";

function Auth(props) {
	const { name, email, phone } = props;
	const [isRegister, setIsRegister] = useState(true);
	const [isLogin, setIsLogin] = useState(false);

	const [userName, setUserName] = useState("");
	const [userEmail, setUserEmail] = useState("");
	const [userPhone, setUserPhone] = useState("");
	const [userPassword, setUserPassword] = useState("");
	const [readAgreement, setReadAgreement] = useState(false);
	const [appVerified, setAppVerified] = useState(false);
	const [expandForm, setExpandForm] = useState(false);
	const [counter, setCounter] = useState(0);
	const [otpTry, setOtpTry] = useState(0);
	const [otp, setOtp] = useState("");
	const [showPasswordText, setShowPasswordText] = useState(false);
	const [cred, setCred] = useState("");

	//Element References
	const fNameRef = useRef(null);
	const emailRef = useRef(null);
	const numberRef = useRef(null);
	const passwordRef = useRef(null);
	const credRef = useRef(null);

	const firstNameErrorRef = useRef(null);
	const emailErrorRef = useRef(null);
	const loginErrorRef = useRef(null);
	const phoneNumberErrorRef = useRef(null);
	const passwordErrorRef = useRef(null);
	const readAgreementErrorRef = useRef(null);
	const otpErrorRef = useRef(null);
	const credErrorRef = useRef(null);

	useEffect(() => {
		window.appVerifier = new RecaptchaVerifier(
			auth,
			"recaptcha-container",
			{
				size: "invisible",
				callback: (response) => {
					console.log(`reCaptcha Response`);
				},
				"expired-callback": () => {
					//reCaptcha Response ID expires in about 1 min.
					console.log(`reCaptcha expired`);
					setAppVerified(false);
				},
			},
		);
		if (!appVerified) {
			window.appVerifier.verify();
		}
		return () => {
			window.appVerifier.clear();
		};
	}, [appVerified]);

	useEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer);
	}, [counter]);

	useEffect(() => {
		setUserName(name);
	}, [name]);

	useEffect(() => {
		setUserEmail(email);
	}, [email]);

	useEffect(() => {
		setUserPhone(phone);
	}, [phone]);

	const clearError = () => {
		firstNameErrorRef.current.classList.remove("show");
		emailErrorRef.current.classList.remove("show");
		phoneNumberErrorRef.current.classList.remove("show");
		passwordErrorRef.current.classList.remove("show");
	};

	const validateRegistrationDetails = () => {
		const nameRegex = new RegExp(regexData.name);
		const emailRegex = new RegExp(regexData.email);
		const numberRegex = new RegExp(regexData.mobileNumberCompact);

		clearError();

		switch (true) {
			case !userName:
				firstNameErrorRef.current.classList.add("show");
				firstNameErrorRef.current.innerHTML = "Enter first name";
				fNameRef.current.focus();
				break;

			case !nameRegex.test(userName):
				firstNameErrorRef.current.classList.add("show");
				firstNameErrorRef.current.innerHTML = "Enter valid first name";
				fNameRef.current.focus();
				break;

			case !userEmail:
				emailErrorRef.current.classList.add("show");
				emailErrorRef.current.innerHTML = "Enter email";
				emailRef.current.focus();
				break;

			case !emailRegex.test(userEmail):
				emailErrorRef.current.classList.add("show");
				emailErrorRef.current.innerHTML = "Enter valid email";
				emailRef.current.focus();
				break;

			case !userPhone:
				phoneNumberErrorRef.current.classList.add("show");
				phoneNumberErrorRef.current.innerHTML = "Enter mobile";
				numberRef.current.focus();
				break;

			case !numberRegex.test(userPhone):
				phoneNumberErrorRef.current.classList.add("show");
				phoneNumberErrorRef.current.innerHTML = "Enter valid mobile";
				numberRef.current.focus();
				break;

			case !userPassword:
				passwordErrorRef.current.classList.add("show");
				passwordErrorRef.current.innerHTML = "Enter password";
				passwordRef.current.focus();
				break;

			case !readAgreement:
				readAgreementErrorRef.current.classList.add("error");
				readAgreementErrorRef.current.classList.add("show");
				readAgreementErrorRef.current.innerHTML =
					"Please read the user agreement";
				break;

			default:
				handleSignUp();
				break;
		}
	};

	function handleSignUp() {
		setAppVerified(false);
		if (window.appVerifier) {
			try {
				registerWithPhoneAndOTP(
					userName,
					userEmail,
					userPassword,
					userPhone,
					window.appVerifier,
				)
					.then((userExist) => {
						if (userExist) {
							readAgreementErrorRef.current.classList.add("error");
							readAgreementErrorRef.current.classList.add("show");
							readAgreementErrorRef.current.innerHTML =
								"User Already Exists. Try Logging in.";
						} else if (!userExist) {
							setExpandForm(true);
							setCounter(59);
						}
					})
					.catch((error) => {
						alert(error.message);
					});
			} catch (error) {
				alert(error);
			}
		}
	}

	const resendOTP = () => {
		setAppVerified(false);
		if (otpTry < 3) {
			if (window.appVerifier) {
				setCounter(30);
				registerWithPhoneAndOTP(
					userName,
					userEmail,
					userPassword,
					`+91${userPhone}`,
					window.appVerifier,
				);
			} else {
				console.log("appverifier not found.");
			}
			setOtpTry(otpTry + 1);
		} else {
			otpErrorRef.current.classList.add("error");
			otpErrorRef.current.classList.add("show");
			otpErrorRef.current.innerHTML =
				"Please try signing up with different phone number or email ID.";
			setExpandForm(false);
			setOtpTry(0);
		}
	};

	function onVerifyCodeSubmit() {
		if (otp === "") {
			otpErrorRef.current.classList.add("error");
			otpErrorRef.current.classList.add("show");
			otpErrorRef.current.innerHTML =
				"Please enter OTP you received on provided number.";
		} else {
			verifyPhoneNumber(
				otp,
				userName,
				userEmail,
				userPassword,
				userPhone,
			).catch(function (error) {
				if (
					error.message === "Firebase: Error (auth/invalid-verification-code)."
				) {
					otpErrorRef.current.classList.add("error");
					otpErrorRef.current.classList.add("show");
					otpErrorRef.current.innerHTML = "Invalid Code";
				}
			});
		}
	}

	const goBack = () => {
		setCounter(0);
		setExpandForm(false);
	};

	const resetData = () => {
		setUserName("");
		setUserEmail("");
		setUserPhone("");
		setUserPassword("");
		clearError();
	};

	const handleSwitchForm = () => {
		setIsRegister(!isRegister);
		setIsLogin(!isLogin);
		setUserPassword("");
		setShowPasswordText(false);
		setUserEmail("");
	};

	const handleLogin = () => {
		const emailRegex = new RegExp(regexData.email);
		const numberRegex = new RegExp(regexData.mobileNumberCompact);

		credErrorRef.current.classList.remove("show");
		passwordErrorRef.current.classList.remove("show");

		switch (true) {
			case !cred:
				credErrorRef.current.classList.add("show");
				credErrorRef.current.innerHTML = "Enter Credentials";
				credRef.current.focus();
				break;

			case !emailRegex.test(cred) && !numberRegex.test(cred):
				credErrorRef.current.classList.add("show");
				credErrorRef.current.innerHTML = "Enter valid Email/Phone Number.";
				credRef.current.focus();
				break;

			case !userPassword:
				passwordErrorRef.current.classList.add("show");
				passwordErrorRef.current.innerHTML = "Enter Password";
				passwordRef.current.focus();
				break;
			default:
				logInWithEmailAndPassword(cred, userPassword).catch((err) => {
					if (err.message === "Firebase: Error (auth/user-not-found).") {
						loginErrorRef.current.classList.add("show");
						loginErrorRef.current.innerHTML = "You aren't registered yet.";
					} else if (err.message === "Firebase: Error (auth/wrong-password).") {
						loginErrorRef.current.classList.add("show");
						loginErrorRef.current.innerHTML = "You entered Wrong Credentials";
					} else {
						loginErrorRef.current.classList.add("show");
						loginErrorRef.current.innerHTML = err.message;
					}
				});
				break;
		}
	};

	const signInasGuestEmail = () => {
		sessionStorage.setItem("email", email);
	};

	return (
		<div className="right mt20 register">
			<div className="head">{`${isRegister ? "Register" : "Login"}`}</div>
			{isRegister && (
				<>
					<div style={{ textAlign: "center" }}>
						Already A Member?{" "}
						<span
							style={{ cursor: "pointer", color: "#2E8B98", fontWeight: "700" }}
							onClick={() => {
								handleSwitchForm();
							}}
						>
							Log In
						</span>
					</div>
					{!expandForm && (
						<div className="form">
							<div className="form-fields bb">
								<label>Name</label>
								<input
									className="bb"
									type="text"
									placeholder="Enter Full Name"
									value={userName}
									maxLength={50}
									ref={fNameRef}
									onChange={(e) => setUserName(e.target.value)}
								/>
								<p className="error-msg" ref={firstNameErrorRef}>
									Error Text
								</p>
							</div>
							<div className="form-fields bb">
								<label>Email</label>
								<input
									className="bb"
									ref={emailRef}
									type="text"
									placeholder="Enter Email"
									value={userEmail}
									maxLength={50}
									onChange={(e) => setUserEmail(e.target.value)}
									required
								/>
								<p className="error-msg" ref={emailErrorRef}>
									Error Text
								</p>
							</div>
							<div className="form-fields bb">
								<label>Mobile</label>
								<input
									className="bb"
									type="text"
									ref={numberRef}
									placeholder="Enter Mobile (10 Digits)"
									maxLength={10}
									pattern={"[0-9]{10}"}
									value={userPhone}
									onChange={(e) => setUserPhone(e.target.value)}
								/>
								<p className="error-msg" ref={phoneNumberErrorRef}>
									Error Text
								</p>
							</div>
							<div className="form-fields bb">
								<label>Password</label>
								<input
									className="bb"
									type={showPasswordText ? "text" : "password"}
									placeholder="Enter Password"
									value={userPassword}
									ref={passwordRef}
									maxLength={50}
									onChange={(e) => setUserPassword(e.target.value)}
								/>
								<span className="icon">
									<img
										src={require("../../assets/images/eye-icon.png")}
										alt=""
										className="img-fluid"
										onClick={() => setShowPasswordText(!showPasswordText)}
									/>
								</span>
								<p className="error-msg" ref={passwordErrorRef}>
									Error Text
								</p>
							</div>
							<div className="form-fields no-bg w100">
								<div className="left">
									<input
										type="checkbox"
										name="remember"
										className="checkbox"
										onClick={() => setReadAgreement(!readAgreement)}
									/>{" "}
									I understand and agree with the{" "}
									<Link to="/">Privacy User Agreement</Link> and{" "}
									<Link to="/">Terms of Service</Link> of Voyaah.
								</div>
								<p className="error-msg" ref={readAgreementErrorRef}>
									Error Text
								</p>
							</div>
							<div className="form-fields no-bg">
								<div className="left">
									<input
										type="button"
										value="Reset"
										className="gray-btn mr-5"
										onClick={resetData}
									/>
								</div>
								<div className="right">
									<input
										type="button"
										value="Generate OTP"
										className="green-btn ml-5"
										onClick={validateRegistrationDetails}
									/>
								</div>
							</div>
						</div>
					)}
					{expandForm && (
						<div className="form">
							<div className="form-fields bb">
								<label>OTP</label>
								<input
									className="bb"
									type="text"
									placeholder="Enter OTP"
									value={otp}
									onChange={(e) => setOtp(e.target.value)}
									maxLength={6}
								/>
							</div>
							<button
								className="gray-btn mr-5"
								onClick={resendOTP}
								disabled={counter > 0}
							>
								Resend OTP in{" "}
								<span style={{ color: "green", fontWeight: "bold" }}>
									{" "}
									{counter} sec
								</span>
							</button>
							<div className="form-fields no-bg">
								<div className="left">
									<input
										type="button"
										value="Change Number"
										className="gray-btn mr-5"
										onClick={goBack}
									/>
								</div>
								<div className="right">
									<input
										type="button"
										value="Submit OTP"
										className="green-btn ml-5"
										onClick={onVerifyCodeSubmit}
									/>
								</div>
								<p className="error-msg" ref={otpErrorRef}>
									Error Text
								</p>
							</div>
						</div>
					)}
					<div id="recaptcha-container" />
				</>
			)}
			{isLogin && (
				<>
					<div style={{ textAlign: "center" }}>
						Don’t have an account?{" "}
						<span
							style={{ cursor: "pointer", color: "#2E8B98", fontWeight: "700" }}
							onClick={() => {
								handleSwitchForm();
							}}
						>
							Sign up
						</span>{" "}
						now
					</div>
					<div className="form">
						<div className="form-fields bb">
							<label>Email/Mobile</label>
							<input
								className="bb"
								ref={credRef}
								type="text"
								placeholder="Enter Email/Phone Number"
								value={cred}
								onChange={(e) => setCred(e.target.value)}
							/>
							<p className="error-msg" ref={credErrorRef}>
								Error Text
							</p>
						</div>
						<div className="form-fields bb">
							<label>Password</label>
							<input
								className="bb"
								ref={passwordRef}
								type={showPasswordText ? "text" : "password"}
								placeholder="Enter Password"
								value={userPassword}
								onChange={(e) => setUserPassword(e.target.value)}
							/>
							<p className="error-msg" ref={passwordErrorRef}>
								Error Text
							</p>
							<span className="icon">
								<img
									src={require("../../assets/images/eye-icon.png")}
									alt=""
									className="img-fluid"
									onClick={() => setShowPasswordText(!showPasswordText)}
								/>
							</span>
						</div>
						<div className="form-fields no-bg">
							<input
								type="button"
								value="Login"
								className="green-btn ml-5"
								onClick={() => handleLogin()}
							/>
						</div>
						<p className="error-msg" ref={loginErrorRef}>
							Error Text
						</p>
					</div>
				</>
			)}
			<div className="splitter"></div>
			<div style={{ textAlign: "center" }}>
				<span
					style={{ cursor: "pointer", color: "#2E8B98", fontWeight: "700" }}
					onClick={() => {
						guestSignIn();
						signInasGuestEmail();
					}}
				>
					Checkout as Guest
				</span>
			</div>
		</div>
	);
}

export default Auth;
