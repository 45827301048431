import { useEffect, useState } from "react";
import { PropertyOverLayFilterData as fd } from "../../constants/listingData";
import "./PropertyOverlay.scss";
import _ from "underscore";
import { GET_PROPERTY_LISTING } from "src/helpers/url_helper";
import { experienceApiAxios } from "src/helpers/api_helper";
import { OverlayPagination } from "./OverlayPagination";
import itineraryPlanStore from "src/store/itineraryPlanStore";
import { observer } from "mobx-react-lite";
import { Property } from "src/models/models";

const useDebounce = (value: any, delay: any) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const PropertyOverlay = observer(
  ({
    cityName,
    onClosePropertyOverlay,
    newPropertyDetails,
    propertyId,
    removeRoomtype,
    opportunityStaysId,
    loader,
    destinationIndex,
  }: {
    onClosePropertyOverlay: any;
    cityName: string;
    newPropertyDetails: any;
    propertyId: string;
    removeRoomtype: any;
    opportunityStaysId: any;
    loader: any;
    destinationIndex?: number;
  }) => {
    const [defaultListCount] = useState(20);
    const [propertyList, setPropertyList] = useState<any[]>([]);
    const [propertyCount, setPropertyCount] = useState(0);
    let filterDataObj: any[] = JSON.parse(JSON.stringify(fd));
    const [filterData, setFilterData] = useState<any[]>(filterDataObj);
    const [isFilterCollapse, setIsFilterCollapse] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    const [searchText, setSearchtext] = useState<any>(" ");
    const debouncedSearchText = useDebounce(searchText, 1000);
    const [tags, setTags] = useState<string>("");
    const [type, setType] = useState<string>("");
    const [change, setChange] = useState<boolean>(true);

    useEffect(() => {
      experienceApiAxios
        .get(
          `${GET_PROPERTY_LISTING}?pageSize=${pageSize}&currentPage=${currentPage}&locations=${cityName}&zoneQuery=${
            searchText.length === 0 ? " " : searchText
          }${type.length === 0 ? "" : `&type=${type}`}${
            tags.length === 0 ? "" : `&tags=${tags}`
          }&channelOfSale=channelOfSale.Voyaah&isActive=true`
        )
        .then((response) => {
          const sortedItems = response.data.propertyList.sort(
            (a: any, b: any) => a.displayIndex - b.displayIndex
          );
          setPropertyList(sortedItems);
          setPropertyCount(response.data.recordCount);
        });
    }, [currentPage, cityName, change, debouncedSearchText]);

    const handleFilterExpand = (item: any) => {
      let itemIndex = filterData.findIndex(
        (obj: any) => obj.title === item.title
      );
      let filterDataObj = [...filterData];
      filterDataObj[itemIndex].isExpand = !item.isExpand;
      setFilterData(filterDataObj);
    };

    const handleItemClick = (item: any, data: any) => {
      let itemIndex = filterData.findIndex(
        (obj: any) => obj.title === item.title
      );
      let dataIndex = filterData[itemIndex].data.findIndex(
        (obj: any) => obj.title === data.title
      );
      let filterDataObj = [...filterData];
      filterDataObj[itemIndex].data[dataIndex].isSelected = !data.isSelected;
      if (data.data) {
        filterDataObj[itemIndex].data[dataIndex].data = _.map(
          filterDataObj[itemIndex].data[dataIndex].data,
          function (a) {
            a.isSelected = filterDataObj[itemIndex].data[dataIndex].isSelected;
            return a;
          }
        );
      }
      setFilterData(filterDataObj);
      filterDataObj.forEach((filter) => {
        if (filter.title === "Property Type") {
          let subQuery = "";
          filter.data.forEach((selectedFilter: any) => {
            if (selectedFilter.isSelected) {
              if (subQuery.length === 0) {
                subQuery += `${selectedFilter.value}`;
              } else {
                subQuery += `,${selectedFilter.value}`;
              }
            }
            if (subQuery.length !== 0) {
              setType(subQuery);
            } else {
              setType("");
            }
          });
        }
        if (filter.title === "Themes") {
          let subQuery = "";
          filter.data.forEach((selectedFilter: any) => {
            if (selectedFilter.isSelected) {
              if (subQuery.length === 0) {
                subQuery += `${selectedFilter.value}`;
              } else {
                subQuery += `,${selectedFilter.value}`;
              }
            }
            if (subQuery.length !== 0) {
              setTags(subQuery);
            } else {
              setTags("");
            }
          });
        }
      });
    };

    const handleSubItemClick = (item: any, data: any, subData: any) => {
      let itemIndex = filterData.findIndex(
        (obj: any) => obj.title === item.title
      );
      let dataIndex = filterData[itemIndex].data.findIndex(
        (obj: any) => obj.title === data.title
      );
      let subDataIndex = filterData[itemIndex].data[dataIndex].data.findIndex(
        (obj: any) => obj.title === subData.title
      );
      let filterDataObj = [...filterData];
      filterDataObj[itemIndex].data[dataIndex].data[subDataIndex].isSelected =
        !subData.isSelected;

      setFilterData(filterDataObj);
    };

    const handlePageChange = (action: any) => {
      let _pNumb = currentPage;
      switch (true) {
        case action === "prev":
          setCurrentPage(currentPage - 1);
          _pNumb--;
          break;
        case action === "next":
          setCurrentPage(currentPage + 1);
          _pNumb++;
          break;
        case !isNaN(action):
          setCurrentPage(action);
          _pNumb = action;
          break;
        default:
          break;
      }
    };

    const handleApply = () => {
      let ab = change ? false : true;
      setChange(ab);
    };

    return (
      <div className="property-overlay">
        <div className="property-listing-popup">
          <div className="overlay-header">
            <div className="overlay-header-text">
              <div className="overlay-header-txt">
                Select Hotel - {cityName}
              </div>
            </div>
            <div className="places-overlay-header-search-close">
              <input
                className="places-hotel-search-txt"
                type="text"
                placeholder="Search for hotels"
                onChange={(e) => {
                  setSearchtext(e.target.value);
                }}
              />
              <button
                className="places-close-btn"
                onClick={() => onClosePropertyOverlay(false)}
              >
                &#x2715;
              </button>
            </div>
          </div>
          <div className="overlay-content">
            <div className="filter-div">
              <div className="property-filter-by">
                <div className="head-container">
                  <div className="head">
                    Filter By
                    <span
                      className={`arrow ${!isFilterCollapse ? "active" : ""}`}
                    >
                      <img
                        src={require("../../assets/images/filter-arrow.png")}
                        alt=""
                        className="img-fluid"
                        onClick={() => {
                          setIsFilterCollapse(!isFilterCollapse);
                        }}
                      />
                    </span>
                  </div>
                </div>
                {!isFilterCollapse && (
                  <>
                    {filterData.map((item: any, index: number) => {
                      return (
                        <div className="acc-blk" key={`filter_${index}`}>
                          <div
                            className="acc-head"
                            onClick={() => handleFilterExpand(item)}
                          >
                            {item.title}
                            <span
                              className={`arrow ${
                                item.isExpand ? "active" : ""
                              }`}
                            >
                              <img
                                src={require("../../assets/images/filter-arrow.png")}
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                          </div>
                          {item.isExpand && (
                            <div className="acc-content">
                              <ul>
                                {item.data.map((data: any, index: number) => {
                                  return (
                                    <li key={`filter_item_${index}`}>
                                      <span
                                        className={`checkbox ${
                                          data.isSelected ? "active" : ""
                                        }`}
                                        onClick={() =>
                                          handleItemClick(item, data)
                                        }
                                      ></span>
                                      <span
                                        onClick={() =>
                                          handleItemClick(item, data)
                                        }
                                      >
                                        {data.title}
                                      </span>

                                      {data.data && (
                                        <ul>
                                          {data.data.map(
                                            (subData: any, index: number) => {
                                              return (
                                                <li
                                                  key={`filter_sub_item_${index}`}
                                                >
                                                  <span
                                                    className={`checkbox ${
                                                      subData.isSelected
                                                        ? "active"
                                                        : ""
                                                    }`}
                                                    onClick={() =>
                                                      handleSubItemClick(
                                                        item,
                                                        data,
                                                        subData
                                                      )
                                                    }
                                                  ></span>
                                                  <span
                                                    onClick={() =>
                                                      handleSubItemClick(
                                                        item,
                                                        data,
                                                        subData
                                                      )
                                                    }
                                                  >
                                                    {subData.title}
                                                  </span>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          )}
                        </div>
                      );
                    })}
                    <div
                      className="apply-btn"
                      onClick={() => {
                        handleApply();
                      }}
                    >
                      Apply
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="places-results-div">
              <div className="places-results-div-content">
                {propertyList.map((property) => {
                  return (
                    <div
                      className="property-listing-section"
                      style={
                        propertyId === property.id
                          ? { backgroundColor: "#116476" }
                          : {}
                      }
                    >
                      <div className="property-listing-img-div">
                        {property.mediaFolder?.files.length > 0 && (
                          <img
                            className="property-listing-img"
                            src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${property.mediaFolder.url}/${property.mediaFolder.files[0].fileName}`}
                          />
                        )}
                      </div>
                      <div className="property-listing-div">
                        <div className="property-listing-header-div">
                          <p className="property-listing-header-txt">
                            {property.name}
                          </p>
                          {propertyId === property.id ? (
                            <button
                              className="property-listing-header-btn selected-btn"
                              onClick={() => {}}
                            >
                              Selected
                            </button>
                          ) : (
                            <button
                              className="property-listing-header-btn unselected-btn"
                              onClick={() => {
                                newPropertyDetails(property);

                                if (
                                  destinationIndex != null &&
                                  destinationIndex != undefined &&
                                  !opportunityStaysId
                                ) {
                                  const deleteRoomType =
                                    itineraryPlanStore?.stayRoomTypes?.find(
                                      (room) =>
                                        room.destinationIndex ===
                                        destinationIndex
                                    );
                                  if (deleteRoomType) {
                                    const removeStay =
                                      itineraryPlanStore?.stayRoomTypes?.filter(
                                        (room) =>
                                          room.destinationIndex !==
                                          destinationIndex
                                      );
                                    itineraryPlanStore?.setStayRoomTypes(
                                      removeStay
                                    );
                                  }
                                }

                                if (opportunityStaysId) {
                                  const deleteRoomType =
                                    itineraryPlanStore?.stayRoomTypes?.find(
                                      (room) =>
                                        room.destinationIndex ===
                                        destinationIndex
                                    );
                                  if (deleteRoomType) {
                                    const removeStay =
                                      itineraryPlanStore?.stayRoomTypes?.filter(
                                        (room) =>
                                          room.destinationIndex !==
                                          destinationIndex
                                      );

                                    const activeRoomTypes =
                                      property?.roomTypes?.filter(
                                        (room: any) =>
                                          room?.statusCode ===
                                          "roomTypeStatus.Active"
                                      );

                                    const roomTypeObj = {
                                      propertyId: property.id,
                                      roomId: activeRoomTypes[0]?.id,
                                      roomName: activeRoomTypes[0]?.name,
                                      mealPlan: "",
                                      rateClass: "",
                                      destinationIndex: destinationIndex,
                                    };
                                    removeStay.push(roomTypeObj);

                                    removeStay.sort(
                                      (a, b) =>
                                        a.destinationIndex - b.destinationIndex
                                    );

                                    itineraryPlanStore?.setStayRoomTypes(
                                      removeStay
                                    );
                                    removeRoomtype(removeStay);

                                    const propertyToReplace: Property =
                                      itineraryPlanStore.propertyList.find(
                                        (property) =>
                                          property.destinationIndex ===
                                          destinationIndex
                                      )!;

                                    const filteredPropertyList =
                                      itineraryPlanStore.propertyList.filter(
                                        (property) =>
                                          property.destinationIndex !==
                                          destinationIndex
                                      );

                                    filteredPropertyList.push({
                                      ...propertyToReplace,
                                      propertyId: property.id,
                                      propertyName: property.name,
                                      roomTypeId: property?.roomTypes[0]?.id,
                                      roomTypeName:
                                        property?.roomTypes[0]?.name,
                                      mealPlan: "",
                                      destinationIndex: destinationIndex,
                                      numRooms: 1,
                                    });

                                    filteredPropertyList.sort(
                                      (a, b) =>
                                        a.destinationIndex! -
                                        b.destinationIndex!
                                    );

                                    itineraryPlanStore.setPropertyList(
                                      filteredPropertyList
                                    );
                                  }
                                  loader(true);
                                }
                                onClosePropertyOverlay(false);
                              }}
                            >
                              Select
                            </button>
                          )}
                        </div>
                        <div className="property-listing-details-div">
                          <div className="property-listing-details-location">
                            {property.address}
                          </div>
                          <div className="property-listing-details-text">
                            {/* {property.descriptionText} */}
                            {property.descriptionText.length > 200
                              ? property.descriptionText.substring(0, 500) +
                                "..."
                              : property.descriptionText}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
                {propertyList.length === 0 && (
                  <p
                    className="no-properties-msg"
                    style={{ color: "rgb(17, 100, 118)" }}
                  >
                    There are no properties available with this search criteria.
                  </p>
                )}
              </div>
              <div className="results-div-pagination">
                {propertyList.length > 0 && (
                  <OverlayPagination
                    onPageChange={handlePageChange}
                    currentPage={currentPage}
                    totalRecords={propertyCount}
                    listCount={defaultListCount}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PropertyOverlay;
