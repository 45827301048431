import itineraryPlanStore from "src/store/itineraryPlanStore";
import { useEffect, useState } from "react";
import { toJS } from "mobx";
import { Property } from "src/models/models";
import ImageGalleryPopup from "./ImageGalleryPopup";
import _ from "underscore";
import { amenitiesIconMapping } from "src/constants/iconMapping";
import RoomTypesContainer from "./RoomTypesContainer";
import {
  countEndDays,
  countStartDays,
  getDateStrInYYYYMMDD,
} from "src/helpers/utils";
import PropertyOverlay from "../propertyOverlay/PropertyOverlay";
import { observer } from "mobx-react-lite";
import { useDispatch } from "react-redux";
import { getPackagePricing } from "src/store/details/actions";
import rightArrowImg from "../../assets/images/enable-right.png";
import leftArrowImg from "../../assets/images/enable-left.png";

const PackageItineraryStays = observer(
  ({
    cityName,
    packageType,
    itineraryPlansData,
    packageId,
    loader,
  }: {
    cityName: string;
    packageType: any;
    itineraryPlansData: any[];
    packageId: any;
    loader: any;
  }) => {
    let dispatch = useDispatch();

    /* setting states here */
    const [startIndex, setStartIndex] = useState(0);
    const [carouselIndex, setCarouselIndex] = useState<any[]>([]);
    const [viewMoreId, setViewMoreId] = useState("");
    const [roomList, setRoomList] = useState<any[]>([]);
    const [policyList, setPolicyList] = useState<any[]>([]);
    const [facilityLength, setFacilityLength] = useState(4);
    const [showCarousel, setShowCarousel] = useState(false);
    const [propDescId, setPropDescId] = useState<string>("");
    const [selectedCity, setSelectedCity] = useState<string>();
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [propertyOverlay, setPropertyOverlay] = useState(false);
    const [propertyDetails, setPropertyDetails] = useState<any>({});
    const [selectRoomTypes, setSelectRoomTypes] = useState<any[]>([]);
    const [selectedPropertyId, setSelectedPropertyId] = useState<any>("");
    const [imageOverLayArray, setImageOverLayArray] = useState<any[]>([]);
    const [selectedProperties, setSelectedProperties] = useState<any[]>([]);
    const [singlExpandedRooms, setSingleExpandedRooms] = useState<{
      [key: string]: boolean;
    }>({});

    const defaultRoomList = [
      { value: "", title: "Select number of rooms" },
      { value: 1, title: "1 Room" },
      { value: 2, title: "2 Rooms" },
      { value: 3, title: "3 Rooms" },
      { value: 4, title: "4 Rooms" },
    ];

    useEffect(() => {
      if (itineraryPlansData && itineraryPlansData.length > 0) {
        itineraryPlanStore.setStateToInitialForm();

        const propertyListArr: Property[] = [];
        const stayRoomTypesArr: any[] = [];
        const selectedPropertiesArr: any[] = [];
        const carouselIndexArr: any[] = [];
        itineraryPlansData.forEach((stayObj: any, index) => {
          carouselIndexArr.push({ propertyIndex: index, startIndex: 0 });
          const propertyListObj: Property = {
            id: stayObj.id,
            dayIndexFrom: stayObj.dayIndexFrom,
            dayIndexTo: stayObj.dayIndexTo,
            propertyId: stayObj.propertyId,
            propertyName: stayObj.propertyName,
            roomTypeId: stayObj.roomTypeId,
            roomTypeName: stayObj.roomTypeName,
            destination: stayObj.destination,
            cityId: stayObj.cityId,
            mealPlan: "",
            numRooms: stayObj.numRooms,
            fromDate: stayObj.fromDate,
            toDate: stayObj.toDate,
            notes: stayObj.notes,
            destinationIndex: index + 1,
          };

          const stayRoomTypeObj = {
            propertyId: stayObj.propertyId,
            roomId: stayObj.roomTypeId,
            roomName: stayObj.roomTypeName,
            mealPlan: "",
            rateClass: "",
            destinationIndex: index + 1,
          };

          const selectedPropertyObj = {
            ...stayObj.property,
            destinationIndex: index + 1,
          };

          propertyListArr.push(propertyListObj);
          stayRoomTypesArr.push(stayRoomTypeObj);
          selectedPropertiesArr.push(selectedPropertyObj);
        });
        setCarouselIndex(carouselIndexArr);
        setSelectRoomTypes(stayRoomTypesArr);
        setSelectedProperties(selectedPropertiesArr);
        itineraryPlanStore.setPropertyList(propertyListArr);
        itineraryPlanStore.setStayRoomTypes(stayRoomTypesArr);
        itineraryPlanStore.setSelectedProperties(selectedPropertiesArr);
      }
    }, [itineraryPlansData]);

    useEffect(() => {
      if (propertyDetails) {
        const filteredSelectedProperties =
          itineraryPlanStore.selectedProperties.filter(
            (property) => property.destinationIndex !== selectedIndex
          );

        filteredSelectedProperties.push({
          ...propertyDetails,
          destinationIndex: selectedIndex,
        });

        filteredSelectedProperties.sort(
          (a, b) => a.destinationIndex - b.destinationIndex
        );

        itineraryPlanStore.setSelectedProperties(filteredSelectedProperties);
        let carouselIndexArr:any[] = [] 
        filteredSelectedProperties.map((item:any,index)=>carouselIndexArr.push({propertyIndex:index,startIndex:0}))
        setSelectedProperties(filteredSelectedProperties);
        loader(false);

        if (itineraryPlanStore.calenderSelected) {
          makeAvailabilityCall();
        }
      }
    }, [propertyDetails]);

    useEffect(() => {
      if (itineraryPlanStore.availabilityData) {
        const roomList: any[] = [];
        const propertyListArr: Property[] = [];
        const stayRoomsArr: any[] = [];
        const policyDataLists: any[] = [];

        if (itineraryPlanStore?.availabilityData?.propertyPricing?.length > 0) {
          itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
            (element: any, propertyIndex: any) => {
              element?.room?.forEach((ele: any) => {
                roomList.push({
                  propertyId: element.propertyId,
                  roomId: ele.roomTypeId,
                  value: ele.numRooms,
                  title: ele.numRooms + " Room",
                  destinationIndex: propertyIndex + 1,
                });

                const propertyListObj: Property =
                  itineraryPlanStore.propertyList.find(
                    (property) =>
                      property.destinationIndex === propertyIndex + 1
                  )!;

                propertyListArr.push({
                  ...propertyListObj,
                  mealPlan: ele.mealPlan,
                  numRooms: ele.numRooms,
                });

                const stayRoomTypeObj = itineraryPlanStore.stayRoomTypes.find(
                  (stayRoom) => stayRoom.destinationIndex === propertyIndex + 1
                )!;

                stayRoomsArr.push({
                  ...stayRoomTypeObj,
                  mealPlan: ele.mealPlan,
                  rateClass: ele.rateClass,
                });

                policyDataLists.push({
                  text: compareDates(
                    ele?.priceData?.typeWisePriceBreakDown[0]
                      ?.roomDistribution[0]?.cancellationPolicies[0]
                      ?.ifCancelledBy
                  )
                    ? compareDates(
                        ele?.priceData?.typeWisePriceBreakDown[0]
                          ?.roomDistribution[0]?.cancellationPolicies[0]
                          ?.ifCancelledBy
                      )
                    : "",
                  roomId: ele.roomTypeId,
                  destinationIndex: propertyIndex + 1,
                });
              });
            }
          );

          itineraryPlanStore.setPropertyList(propertyListArr);
          itineraryPlanStore.setStayRoomTypes(stayRoomsArr);
          setSelectRoomTypes(stayRoomsArr);
          setPolicyList(policyDataLists);
          itineraryPlanStore.setpolicyLists(policyDataLists);
          setRoomList(roomList);
        }
      }
    }, [itineraryPlanStore.availabilityData]);

    const singleToggleAmenities = (roomId: string) => {
      setSingleExpandedRooms((prev) => ({
        ...prev,
        [roomId]: !prev[roomId],
      }));
    };

    const prevImages = (pIndex: number) => {
      let existingCarouselIndex = carouselIndex.filter((item:any)=>item.propertyIndex !== pIndex)
      let newCarouselIndex = carouselIndex.find((item:any)=>item.propertyIndex === pIndex)

      if (newCarouselIndex?.startIndex > 0) {
        newCarouselIndex.startIndex = newCarouselIndex?.startIndex  - 3;
        existingCarouselIndex.push(newCarouselIndex)
      }
      setCarouselIndex(existingCarouselIndex)
    };

    const nextImages = (carouselImagesLength: any,pIndex:number) => {
      let existingCarouselIndex = carouselIndex.filter((item:any)=>item.propertyIndex !== pIndex)
      let newCarouselIndex = carouselIndex.find((item:any)=>item.propertyIndex === pIndex)

      if (newCarouselIndex?.startIndex + 3 < carouselImagesLength) {
        newCarouselIndex.startIndex = newCarouselIndex?.startIndex + 3;
        existingCarouselIndex.push(newCarouselIndex)
        
      }
      setCarouselIndex(existingCarouselIndex);
    };

    const makeAvailabilityCall = () => {
      const data: any[] = [];
      const startDate = itineraryPlanStore.rightContainerData.fromDate;
      const endDate = itineraryPlanStore.rightContainerData.toDate;

      let toDate = new Date(startDate).setHours(0, 0, 0, 0);
      let fromDate = new Date(startDate).setHours(0, 0, 0, 0);
      if (itineraryPlansData && itineraryPlansData.length > 0) {
        itineraryPlanStore.stayRoomTypes.forEach((stayRoomObj: any) => {
          let dayIndexFrom =
            itineraryPlansData[stayRoomObj.destinationIndex - 1]?.dayIndexFrom;
          let dayIndexTo =
            itineraryPlansData[stayRoomObj.destinationIndex - 1]?.dayIndexTo;

          fromDate = toDate;
          toDate =
            packageType === "packageType.Staycation"
              ? endDate
              : new Date(
                  new Date(fromDate).setDate(
                    new Date(fromDate).getDate() +
                      (dayIndexTo - dayIndexFrom) +
                      1
                  )
                ).setHours(0, 0, 0, 0);

          data.push({
            propertyId: stayRoomObj.propertyId,
            roomTypeId: stayRoomObj.roomId,
            mealPlan: stayRoomObj.mealPlan,
            rateClass: stayRoomObj.rateClass,
            fromDate: getDateStrInYYYYMMDD(new Date(fromDate)),
            toDate: getDateStrInYYYYMMDD(new Date(toDate)),
            numRooms:
              itineraryPlanStore.propertyList.find(
                (property) =>
                  property.destinationIndex === stayRoomObj.destinationIndex
              )?.numRooms ?? 1,
            numAdults: itineraryPlanStore.rightContainerData.numAdults,
            numChildren: itineraryPlanStore.rightContainerData.numChildren,
            childAges: itineraryPlanStore.rightContainerData.childAges,
          });
        });

        itineraryPlanStore.setavailabilitypayload(data);
        dispatch(getPackagePricing(data, "", "", packageId));
      }
    };

    const updateShowImageGalleryPopup = (data: any) => {
      setShowCarousel(data);
    };

    const moreFacilitylist = (total: number, propertyId: any) => {
      setFacilityLength(total);
      if (total === 4) {
        setViewMoreId("");
      } else {
        setViewMoreId(propertyId);
      }
    };

    const getIcon = (text: string) => {
      const textVar = text?.toLowerCase();
      let bestMatch = "";
      let bestMatchIndex = 0;
      let matchingIcons = amenitiesIconMapping.filter((icon: any) =>
        textVar?.includes(icon.keyword)
      );
      for (let i = 0; i < matchingIcons.length; i++) {
        if (matchingIcons[i].keyword !== undefined) {
          if (matchingIcons[i].keyword.length > bestMatch.length) {
            bestMatch = matchingIcons[i].keyword;
            bestMatchIndex = i;
          }
        }
      }
      if (matchingIcons.length === 0)
        return require(`../../assets/images/icons/highlights/default.png`);
      return require(`../../assets/images/icons/amenities/${matchingIcons[bestMatchIndex].icon}`);
    };

    const getPropertyImagesArray = (
      mediaFolderFiles: any,
      mediaFolderUrl: string
    ) => {
      const imageArray: any[] = [];
      mediaFolderFiles?.map((file: any) => {
        const tempImg = {
          imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${mediaFolderUrl}/${file?.fileName}`,
          imageCaption: file.fileName,
        };
        imageArray.push(tempImg);
      });
      const mediaOverLayArray = [
        {
          collectionType: "Additional",
          propertyId: null,
          roomTypeId: null,
          images: imageArray,
        },
      ];

      setImageOverLayArray(mediaOverLayArray);
    };

    const handleRoomTypeSelect = (
      id: any,
      name: any,
      propertyId: any,
      destinationIndex: number
    ) => {
      const findRoomType = itineraryPlanStore.stayRoomTypes.find(
        (room) => room.destinationIndex === destinationIndex
      );

      if (findRoomType) {
        const deleteRoomTypeFromStore = itineraryPlanStore.stayRoomTypes.filter(
          (room) => room.destinationIndex !== destinationIndex
        );

        const roomTypeObj = {
          propertyId: propertyId,
          roomId: id,
          roomName: name,
          mealPlan: "",
          rateClass: "",
          destinationIndex: destinationIndex,
        };

        deleteRoomTypeFromStore.push(roomTypeObj);

        deleteRoomTypeFromStore.sort(
          (a, b) => a.destinationIndex! - b.destinationIndex!
        );

        setSelectRoomTypes(deleteRoomTypeFromStore);
        itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);

        const propertyListObj: Property = itineraryPlanStore.propertyList.find(
          (property) => property.destinationIndex === destinationIndex
        )!;

        const filteredPropertyList = itineraryPlanStore.propertyList.filter(
          (property) => property.destinationIndex !== destinationIndex
        );

        filteredPropertyList.push({
          ...propertyListObj,
          roomTypeId: id,
          roomTypeName: name,
          mealPlan: "",
          numRooms: 1,
        });

        filteredPropertyList.sort(
          (a, b) => a.destinationIndex! - b.destinationIndex!
        );

        itineraryPlanStore.setPropertyList(filteredPropertyList);

        if (itineraryPlanStore.calenderSelected) {
          makeAvailabilityCall();
        }
      }
    };

    const handleMealPlanChange = (
      destinationIndex: number,
      mealPlanObj: string
    ) => {
      if (mealPlanObj) {
        const mealPlanSplit = mealPlanObj.split("-");

        const findRoomType = itineraryPlanStore.stayRoomTypes.find(
          (room) => room.destinationIndex === destinationIndex
        );

        if (findRoomType) {
          const deleteRoomTypeFromStore =
            itineraryPlanStore.stayRoomTypes.filter(
              (room) => room.destinationIndex !== destinationIndex
            );

          const roomTypeObj = {
            ...findRoomType,
            mealPlan: mealPlanSplit[0],
            rateClass: mealPlanSplit[1],
            destinationIndex: destinationIndex,
          };

          deleteRoomTypeFromStore.push(roomTypeObj);
          deleteRoomTypeFromStore.sort(
            (a, b) => a.destinationIndex! - b.destinationIndex!
          );

          setSelectRoomTypes(deleteRoomTypeFromStore);
          itineraryPlanStore.setStayRoomTypes(deleteRoomTypeFromStore);

          const propertyListObj: Property =
            itineraryPlanStore.propertyList.find(
              (property) => property.destinationIndex === destinationIndex
            )!;

          const filteredPropertyList = itineraryPlanStore.propertyList.filter(
            (property) => property.destinationIndex !== destinationIndex
          );

          filteredPropertyList.push({
            ...propertyListObj,
            mealPlan: mealPlanSplit[0],
          });

          filteredPropertyList.sort(
            (a, b) => a.destinationIndex! - b.destinationIndex!
          );

          itineraryPlanStore.setPropertyList(filteredPropertyList);

          makeAvailabilityCall();
        }
      } else {
        return;
      }
    };

    const handleRoomNumberChange = (
      destinationIndex: number,
      roomCount: number
    ) => {
      if (!isNaN(roomCount)) {
        const updatePropertyObj: Property =
          itineraryPlanStore.propertyList.find(
            (propertyObj) => propertyObj.destinationIndex === destinationIndex
          )!;

        const filteredPropertyArr = itineraryPlanStore.propertyList.filter(
          (propertyObj) => propertyObj.destinationIndex !== destinationIndex
        );

        filteredPropertyArr.push({
          ...updatePropertyObj,
          numRooms: roomCount,
        });

        filteredPropertyArr.sort(
          (a, b) => a.destinationIndex! - b.destinationIndex!
        );

        itineraryPlanStore.setPropertyList(filteredPropertyArr);
        makeAvailabilityCall();
      } else {
        return;
      }
    };

    const getCheckInCheckOutDates = (fromIndex: any, toIndex: any) => {
      let nnights: any = "";
      let totalStays: any = "";
      nnights = toIndex - fromIndex;

      totalStays = `${nnights + 1}N ${nnights + 2}D`;
      let datesstays: any = "";

      if (!itineraryPlanStore.calenderSelected) {
        datesstays = "";
        return totalStays;
      } else {
        datesstays =
          countStartDays(
            getDateStrInYYYYMMDD(
              new Date(itineraryPlanStore.rightContainerData.fromDate)
            ),
            fromIndex
          ) +
          " to " +
          countEndDays(
            getDateStrInYYYYMMDD(
              new Date(itineraryPlanStore.rightContainerData.fromDate)
            ),
            toIndex
          );
        return totalStays + " - " + datesstays;
      }
    };

    const getMealPlanRatePlanMapping = (
      roomId: any,
      destinationIndex: number
    ) => {
      let mappings: any[] = [];
      if (
        itineraryPlanStore.calenderSelected &&
        itineraryPlanStore?.availabilityData?.propertyPricing?.length > 0
      ) {
        itineraryPlanStore?.availabilityData?.propertyPricing?.forEach(
          (element: any, propertyIndex: number) => {
            if (propertyIndex === destinationIndex - 1) {
              element?.room?.forEach((elementRoom: any) => {
                if (elementRoom.roomTypeId === roomId && !elementRoom.error) {
                  element.availableMealPlan.forEach((ele: any) => {
                    mappings.push({
                      value: ele.mealPlan + "-" + ele.rateClass,
                      title: ele.mealPlanDesc + "-" + ele.rateClassDesc,
                    });
                  });
                }
                else if (
                  elementRoom.roomTypeId === roomId &&
                  elementRoom.error
                ) {
                  element.availableMealPlan.forEach((ele: any) => {
                    mappings.push({
                      value: "-",
                      title: "-",
                    });
                  });
                }
              });
            }
          }
        );
      }

      if (mappings.length === 0) {
        mappings.push({
          value: "-",
          title: "Choose a meal plan",
        });
      }

      const inventoryFailed = mappings.find(
        (x) => x.value === "-" && x.title === "-"
      );

      if (inventoryFailed) {
        mappings = [];
        mappings.push({
          value: "-",
          title: "No Inventory Available",
        });
      }
      return mappings;
    };

    const getSelectedRoomCount = (destinationIndex: any, roomId: any) => {
      let roomCount = 0;
      if (roomList?.length > 0) {
        roomList?.forEach((ele: any, i: any) => {
          if (
            ele.destinationIndex === destinationIndex &&
            ele.roomId === roomId
          ) {
            roomCount = ele.value;
          }
        });
      }
      return roomCount;
    };

    const getMealPlanValueForRoom = (destinationIndex: number) => {
      const findRoom = itineraryPlanStore.stayRoomTypes.find(
        (stayRoom) => stayRoom.destinationIndex === destinationIndex
      );

      if (findRoom && findRoom.mealPlan && findRoom.rateClass) {
        return `${findRoom.mealPlan}-${findRoom.rateClass}`;
      } else {
        return "";
      }
    };

    const compareDates = (policyDate: any) => {
      let result = "";
      if (policyDate) {
        const planPolicyDate = policyDate?.split("T")[0];
        const date = new Date();
        const todaydate: any = getDateStrInYYYYMMDD(date);

        if (todaydate > planPolicyDate) {
          result = "Booking is non-refundable";
        } else {
          result =
            "Free Cancellation Upto " +
            planPolicyDate?.split("-")[2] +
            "-" +
            planPolicyDate?.split("-")[1] +
            "-" +
            planPolicyDate?.split("-")[0];
        }
      } else {
        result = "";
      }
      return result;
    };

    return (
      <div className="pdp-stays-section">
        {selectedProperties &&
          selectedProperties.length > 0 &&
          selectedProperties.map((property, propertyIndex: number) => (
            <div key={property.destinationIndex} className="stays-section">
              <div className="property-stay-section">
                <div className="property-stay-img-div">
                  {property?.mediaFolder?.files.length > 0 && (
                    <div className="property-stay-thumbnail">
                      <img
                        className="property-stay-img"
                        src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${property?.mediaFolder?.url}/${property?.mediaFolder?.files[0]?.fileName}`}
                      />
                      <button
                        className="carousel-toggle-button"
                        onClick={() => {
                          setShowCarousel(!showCarousel);
                          getPropertyImagesArray(
                            property?.mediaFolder?.files,
                            property?.mediaFolder?.url
                          );
                        }}
                      >
                        View more...
                      </button>
                    </div>
                  )}
                  <div className="carousel-container">
                    <button
                      className="carousel-arrow left"
                      disabled={carouselIndex.find(
                        (x) => x.propertyIndex === propertyIndex
                      )?.startIndex == 0}
                      onClick={() => {
                        prevImages(propertyIndex);
                      }}
                    >
                      <img
                        src={leftArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>

                    <div className="carousel-images">
                      {property?.mediaFolder?.files.length > 0 &&
                        property?.mediaFolder?.files
                          .slice(
                            carouselIndex.find(
                              (x) => x.propertyIndex === propertyIndex
                            )?.startIndex,
                            carouselIndex.find(
                              (x) => x.propertyIndex === propertyIndex
                            )?.startIndex + 3
                          )
                          .map((file: any, index: any) => {
                            return (
                              <img
                                key={index}
                                src={`https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${property?.mediaFolder?.url}/${file?.fileName}`}
                                alt={file.fileName}
                              />
                            );
                          })}
                    </div>

                    <button
                      className="carousel-arrow right"
                      disabled={
                        carouselIndex.find(
                          (x) => x.propertyIndex === propertyIndex
                        )?.startIndex +
                          3 >=
                        property?.mediaFolder?.files.length
                      }
                      onClick={() => {
                        console.log(propertyIndex);
                        nextImages(property?.mediaFolder?.files.length,propertyIndex);
                      }}
                    >
                      <img
                        src={rightArrowImg}
                        alt="right-arrow"
                        style={{ height: "12px" }}
                      />
                    </button>
                  </div>
                </div>
                <div className="property-stay-content-button">
                  <div className="property-stay-div">
                    <div className="property-stay-header-div">
                      <div className="property-stay-header-txt test">
                        <div>{property?.name}</div>
                      </div>
                      {itineraryPlansData && itineraryPlansData.length > 0 && (
                        <div className="city-date-div">
                          {property?.city?.name
                            ? property?.city?.name + " - "
                            : ""}
                          {getCheckInCheckOutDates(
                            itineraryPlansData[propertyIndex]?.dayIndexFrom,
                            itineraryPlansData[propertyIndex]?.dayIndexTo
                          )}
                        </div>
                      )}
                      <div className="property-stay-details-location-date">
                        <div className="property-stay-details-bubble">
                          <div className="location-background"></div>

                          <div className="stay-location-date-label">
                            location:
                          </div>
                          <div className="stay-location-date-div">
                            {property?.address}
                          </div>
                        </div>
                      </div>
                    </div>
                    {property?.description && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <div
                          style={{ width: "583px", textAlign: "justify" }}
                          className="property-stay-description test1"
                          dangerouslySetInnerHTML={{
                            __html:
                              property.id !== propDescId
                                ? property?.description?.substring(0, 350)
                                : property?.description,
                          }}
                        />
                        {property?.description?.length > 350 && (
                          <button
                            style={{
                              alignSelf: "flex-start",
                              fontSize: "14px",
                              color: "#2e8b98",
                              cursor: "pointer",
                              border: "none",
                              backgroundColor: "transparent",
                              padding: "3px 0px",
                            }}
                            onClick={() => {
                              if (propDescId === property.id) {
                                setPropDescId("");
                              } else {
                                setPropDescId(property.id);
                              }
                            }}
                          >
                            {property.id !== propDescId && "View more"}
                            {property.id === propDescId && "View less"}
                          </button>
                        )}
                      </div>
                    )}
                    {!property?.description && (
                      <div
                        style={{ width: "583px" }}
                        className="property-stay-description test"
                      ></div>
                    )}
                  </div>
                  <div>
                    {packageType === "packageType.Staycation" ? (
                      ""
                    ) : (
                      <button
                        className="property-stay-header-btn"
                        onClick={() => {
                          setSelectedPropertyId(property.id);
                          setSelectedCity(property.city.name);
                          setPropertyOverlay(!propertyOverlay);
                          setSelectedIndex(property.destinationIndex);
                        }}
                      >
                        Select
                      </button>
                    )}
                  </div>
                </div>
                {showCarousel && (
                  <ImageGalleryPopup
                    updateShowImageGalleryPopup={updateShowImageGalleryPopup}
                    showImageGalleryPopup={showCarousel}
                    packageData={imageOverLayArray}
                  />
                )}
              </div>
              <div className="property-facilities">
                <ul className="inline-block">
                  {_.compact(property?.facilities).length > 0 &&
                    _.compact(property?.facilities)
                      ?.slice(
                        0,
                        viewMoreId === property.id ? facilityLength : 4
                      )
                      .map((item: any, index: number) => {
                        return (
                          <li
                            style={{ fontSize: "14px" }}
                            key={`package_hotel_facilities_${index}`}
                          >
                            <span style={{ width: "20px" }}>
                              <img
                                src={getIcon(item?.name)}
                                alt=""
                                className="img-fluid"
                              />
                            </span>
                            {item?.name}&nbsp;
                            <span>
                              {item?.extractedJson?.toBePaid && " ($)"}
                            </span>
                          </li>
                        );
                      })}
                  {_.compact(property?.facilities).length === 0 && (
                    <li key={`package_hotel_facilities_na`}>{"Not Listed"}</li>
                  )}
                  {property?.mediaFolder?.files?.length === facilityLength &&
                  property?.id === viewMoreId &&
                  property?.facilities?.length > 4 ? (
                    <li
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        color: "#2e8b98",
                      }}
                      onClick={() => {
                        moreFacilitylist(4, property?.id);
                      }}
                    >
                      view less
                    </li>
                  ) : property?.facilities?.length > 4 ? (
                    <li
                      style={{
                        fontSize: "14px",
                        cursor: "pointer",
                        color: "#2e8b98",
                      }}
                      onClick={() => {
                        moreFacilitylist(
                          property?.mediaFolder?.files?.length,
                          property.id
                        );
                      }}
                    >
                      view more
                    </li>
                  ) : (
                    ""
                  )}
                </ul>
              </div>
              <div className="room-child-wrapper">
                {property?.roomTypes?.length > 0 ? (
                  property?.roomTypes?.map((room: any, index: any) => {
                    if (room?.statusCode === "roomTypeStatus.Active") {
                      const imgArr: any[] = [];
                      room.mediaFolder.files.map((file: any) => {
                        const tempImg = {
                          imageCaption: file.fileName,
                          imageUrl: `https://s3.ap-south-1.amazonaws.com/${process.env.REACT_APP_MEDIA_S3_BUCKET}/property/${property?.mediaFolder?.url}/room/${room?.mediaFolder?.url}/${file?.fileName}`,
                        };
                        imgArr.push(tempImg);
                      });

                      return (
                        <div key={index + property.id}>
                          <div className="room-info">
                            <div className="radio-img-carosel">
                              <div className="roomselection-radiobtn">
                                <input
                                  checked={
                                    selectRoomTypes.find(
                                      (x: any) =>
                                        x.roomId === room?.id &&
                                        x.destinationIndex ===
                                          property.destinationIndex
                                    )
                                      ? true
                                      : false
                                  }
                                  onChange={() => {
                                    handleRoomTypeSelect(
                                      room.id,
                                      room.name,
                                      property.id,
                                      property.destinationIndex
                                    );
                                  }}
                                  name={property.destinationIndex + room.id}
                                  type="radio"
                                />
                              </div>
                              <div className="roomimagecontainer">
                                <RoomTypesContainer imageArr={imgArr} />
                              </div>
                            </div>
                            <div className="room-details-div">
                              <div className="room-header">{room.name}</div>
                              <div className="room-details-text">
                                {room?.beds[0]?.count === undefined
                                  ? ""
                                  : room?.beds[0]?.count + "-"}
                                {room?.beds[0]?.description === undefined
                                  ? ""
                                  : room?.beds[0]?.description !== undefined
                                  ? room?.beds[0]?.description?.split(
                                      "bed"
                                    )[0] + "bed"
                                  : ""}
                              </div>
                              <div className="facilities-room-div">
                                {room.amenities
                                  .slice(
                                    0,
                                    singlExpandedRooms[room.id] || false
                                      ? room.amenities.length
                                      : 4
                                  )
                                  .map(
                                    (
                                      amenity: {
                                        name: string;
                                        amenityType: string;
                                        extractedJson: any;
                                      },
                                      index: number
                                    ) => {
                                      return (
                                        <>
                                          <span
                                            key={`package_room_amenities_${index}`}
                                            style={{
                                              width: "14px",
                                            }}
                                          >
                                            <img
                                              src={getIcon(amenity.amenityType)}
                                              alt=""
                                              className="img-fluid"
                                            />
                                          </span>
                                          <span>{amenity.name}&nbsp;</span>
                                          <span>
                                            {amenity?.extractedJson?.toBePaid &&
                                              " ($)"}
                                          </span>
                                        </>
                                      );
                                    }
                                  )}
                                {room.amenities.length > 4 && (
                                  <button
                                    style={{
                                      fontSize: "14px",
                                      cursor: "pointer",
                                      color: "#2e8b98",
                                      border: "none",
                                      backgroundColor: "transparent",
                                      padding: "0",
                                    }}
                                    onClick={() =>
                                      singleToggleAmenities(room.id)
                                    }
                                  >
                                    {singlExpandedRooms[room.id] || false
                                      ? "View less"
                                      : "View more"}
                                  </button>
                                )}
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "15px",
                              }}
                            >
                              <div className="room-count-div">
                                <div className="dropdown-label">Meals:</div>
                                {!itineraryPlanStore.calenderSelected ? (
                                  <select
                                    disabled={
                                      !itineraryPlanStore.calenderSelected
                                    }
                                    className="room-type-dropdown"
                                    style={{ backgroundPosition: "195px" }}
                                  >
                                    <option value="">
                                      Please select travel dates
                                    </option>
                                  </select>
                                ) : (
                                  <select
                                    disabled={
                                      !selectRoomTypes.find(
                                        (x: any) =>
                                          x.roomId === room?.id &&
                                          x.destinationIndex ===
                                            property.destinationIndex
                                      )
                                        ? true
                                        : false &&
                                          !itineraryPlanStore.calenderSelected
                                        ? true
                                        : false
                                    }
                                    className="room-type-dropdown s"
                                    style={{ backgroundPosition: "195px" }}
                                    value={getMealPlanValueForRoom(
                                      property.destinationIndex
                                    )}
                                    onChange={(event) => {
                                      handleMealPlanChange(
                                        property.destinationIndex,
                                        event.target.value
                                      );
                                    }}
                                  >
                                    {getMealPlanRatePlanMapping(
                                      room.id,
                                      property.destinationIndex
                                    )?.map((e: any) => {
                                      return (
                                        <option value={e?.value}>
                                          {e?.title}
                                        </option>
                                      );
                                    })}
                                  </select>
                                )}
                              </div>
                              <div className="room-count-div">
                                <div className="dropdown-label">Rooms:</div>
                                {!itineraryPlanStore.calenderSelected ? (
                                  <select
                                    className="room-type-dropdown"
                                    disabled={
                                      !itineraryPlanStore.calenderSelected
                                    }
                                    style={{
                                      width: "100px",
                                      backgroundPosition: "195px",
                                    }}
                                  >
                                    <option value="">
                                      Select number of rooms
                                    </option>
                                  </select>
                                ) : selectRoomTypes.find(
                                  (x: any) =>
                                    x.roomId === room?.id &&
                                    x.destinationIndex ===
                                      property.destinationIndex
                                ) &&
                                !getMealPlanRatePlanMapping(
                                  room.id,
                                  property.destinationIndex
                                ).find(
                                  (x) =>
                                    x.value ===
                                    getMealPlanValueForRoom(
                                      property.destinationIndex
                                    )
                                ) ? (
                                <select className="room-type-dropdown">
                                  <option value="">
                                    No inventory Available
                                  </option>
                                </select>
                              ) :(
                                  <select
                                    className="room-type-dropdown"
                                    disabled={
                                      !selectRoomTypes.find(
                                        (x: any) =>
                                          x.roomId === room?.id &&
                                          x.destinationIndex ===
                                            property.destinationIndex
                                      )
                                        ? true
                                        : false &&
                                          !itineraryPlanStore.calenderSelected
                                        ? true
                                        : false
                                    }
                                    style={{
                                      width: "100px",
                                      backgroundPosition: "195px",
                                    }}
                                    onChange={(event) => {
                                      handleRoomNumberChange(
                                        property.destinationIndex,
                                        parseInt(event.target.value)
                                      );
                                    }}
                                  >
                                    {defaultRoomList?.map(
                                      (list: any, i: any) => {
                                        if (
                                          list.value &&
                                          selectRoomTypes.find(
                                            (x: any) =>
                                              x.roomId === room?.id &&
                                              x.destinationIndex ===
                                                property.destinationIndex
                                          )
                                        ) {
                                          return (
                                            <option
                                              selected={
                                                getSelectedRoomCount(
                                                  property.destinationIndex,
                                                  room.id
                                                ) === list.value
                                              }
                                              value={list.value}
                                            >
                                              {list.title}
                                            </option>
                                          );
                                        } else if (
                                          list.value &&
                                          !selectRoomTypes.find(
                                            (x: any) =>
                                              x.roomId === room?.id &&
                                              x.destinationIndex ===
                                                property.destinationIndex
                                          )
                                        ) {
                                          return (
                                            <option>
                                              Select number of rooms
                                            </option>
                                          );
                                        } else {
                                          return <></>;
                                        }
                                      }
                                    )}
                                  </select>
                                )}
                              </div>
                              {!(selectRoomTypes.find(
                                  (x: any) =>
                                    x.roomId === room?.id &&
                                    x.destinationIndex ===
                                      property.destinationIndex
                                ) &&
                                !getMealPlanRatePlanMapping(
                                  room.id,
                                  property.destinationIndex
                                ).find(
                                  (x) =>
                                    x.value ===
                                    getMealPlanValueForRoom(
                                      property.destinationIndex
                                    )
                                )) &&
                              <span className="cancellation-status">
                                {policyList.find(
                                  (x: any) =>
                                    x?.destinationIndex ===
                                      property.destinationIndex &&
                                    x?.roomId === room.id
                                )?.text ?? ""}
                              </span>
                            }
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <>No Rooms available</>
                )}
              </div>
            </div>
          ))}
        {propertyOverlay && (
          <PropertyOverlay
            onClosePropertyOverlay={setPropertyOverlay}
            newPropertyDetails={setPropertyDetails}
            opportunityStaysId={selectedPropertyId}
            removeRoomtype={setSelectRoomTypes}
            destinationIndex={selectedIndex}
            propertyId={selectedPropertyId}
            cityName={selectedCity!}
            loader={loader}
          />
        )}
      </div>
    );
  }
);

export default PackageItineraryStays;
